import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';

const Impressum = () => {
	return (
		<Layout>
			<SEO title="Impressum" keywords={[]} />
			<span itemScope itemType="http://schema.org/Article">
				<section id="article">
					<article className="text">
						<div className="ribbon" />
						<header>
							<h2>Impressum</h2>
							<p>&nbsp;</p>
						</header>
						<hr />
						<section>
							<span id="b64" className="b64">
								{Buffer.from(
									'<p><strong>Eduard Baun</strong><br>Im Kirchenfelde 6a<br>33106 Paderborn</p><p>Telefon: 052515089018<br>E-Mail: <a href="mailto:eduard@baun.de">eduard@baun.de</a></p><p>Umsatzsteuer-Identifikationsnummer gem. &sect; 27a UStG: DE 239800490</p><p>Inhaltlich Verantwortlicher nach &sect; 55 II RStV: Eduard Baun (Anschrift s.o.)</p>'
								).toString('base64')}
							</span>
							<p>
								<strong>
									Die Verwendung der obigen Angaben, egal zu welchem Zweck, ist untersagt.
								</strong>
							</p>
						</section>
					</article>
				</section>
			</span>
		</Layout>
	);
};

export default Impressum;
